.main-carousel {
    .carousel-top-layer {
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 500px;

        @include media-breakpoint-down(md) {
            height: 600px;
        }

        @include media-breakpoint-down(sm) {
            height: 650px;
        }
    }

    .carousel-item {
        background: {
            size: cover;
            position: center;
            repeat: no-repeat;
        }
    }

    .carousel-caption {
        right: unset;
        bottom: unset;
        top: 16%;
        left: 9%;
        padding-top: 20px;
        padding-bottom: 20px;
        color: $contrastDarkText;
        text-align: left;

        @include media-breakpoint-down(lg) {
            margin-right: 8rem;
        }

        @include media-breakpoint-down(sm) {
            top: 10%;

            padding: {
                top: 10px;
                bottom: 10px;
            }

            margin: {
                right: 5rem;
            }
        }

        .carousel-caption-title {
            font: {
                size: 3rem;
                weight: 600;
            }
            margin-bottom: 0;
            max-width: 900px;
            @include textShadow;

            @include media-breakpoint-down(xl) {
                font-size: 2.3rem;
            }

            @include media-breakpoint-down(sm) {
                font-size: 1.8rem;
            }
        }

        .carousel-caption-subtitle {
            font-size: 1.1rem;
        }

        .btn {
            &:last-child {
                margin-left: 1rem;

                @include media-breakpoint-down(md) {
                    margin: {
                        left: 0;
                        top: 1rem;
                    }
                }
            }
        }
    }

    .carousel-controlls {
        position: absolute;
        display: flex;
        z-index: 2;
        bottom: 4rem;
        left: 9%;

        @include media-breakpoint-down(md) {
            bottom: 3rem;
        }

        .carousel-control-prev,
        .carousel-control-next {
            position: unset;
            height: 45px;
            width: 45px;
            border: {
                radius: 100px;
                width: 1px;
                style: solid;
                color: $primaryColor;
            }
            color: $primaryColor;
            opacity: 1;
            @include transition;

            &:hover {
                border: 1px solid darken($primaryColor, 20%);
                color: darken($primaryColor, 20%);
            }
        }

        .carousel-control-prev {
            margin-right: 0.5rem;
        }

        svg {
            width: 0.625em;
        }
    }

    .carousel-indicators {
        position: absolute;
        flex-direction: column;
        right: 0;
        bottom: 0;
        top: 0;
        left: unset;
        margin-right: 2rem;
        margin-bottom: unset;
        margin-left: unset;
        list-style: none;

        [data-bs-target] {
            width: 0.8rem;
            height: 0.8rem;
            margin-bottom: 0.3rem;
            border: {
                radius: 1rem;
                style: unset;
                width: unset;
                color: unset;
            }
            box-sizing: unset;
            background-clip: unset;
        }
    }
}

.query {
    padding: {
        top: 3rem;
        bottom: 3rem;
    }

    .title {
        margin-top: 0;
    }

    .queryBlock {
        &__link {
            text-decoration: none;
            color: $contrastLightText;
        }

        &__grid {
            background: {
                color: $lightBackground;
            }
            padding: 3rem 2.5rem;
            margin-top: 1rem;
            height: 400px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include transition;

            &:hover {
                background: {
                    color: darken($lightBackground, 2%);
                }
            }
        }

        &__content {
            flex-direction: column;
        }

        &__image {
            max-width: 40px;
            width: 100%;
            margin-bottom: 1.8rem;
        }

        &__title {
            font-size: 1.5rem;
            font-weight: 800;
            margin: 0;
        }

        &__type {
            color: $primaryColor;
            font-size: 0.8rem;
        }

        &__description {
            margin-top: 1rem;
            margin-bottom: 0.2rem;
            opacity: 0.8;
            font-size: 0.8rem;
        }

        &__value {
            font-weight: 800;
        }
    }
}

.advantage {
    .title {
        margin: 0 0 1.5rem;
    }

    &__content {
        background: {
            color: $lightBackground;
        }
        background-color: #fff;
        padding: 4.3rem 14rem 4.3rem 6rem;

        @include media-breakpoint-down(xl) {
            padding: 4.3rem 6rem;
        }
    }

    &__image {
        margin: 0 0 -54% auto;
        z-index: 2;

        @include media-breakpoint-down(xl) {
            display: none;
        }
    }
}
