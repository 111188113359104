/*====================================
Buttons patterns
====================================*/
@mixin button(
    $textColor: $contrastLightText,
    $backgroundColor: transparent,
    $borderColor: $primaryColor
) {
    border: {
        radius: 1.7rem;
        style: solid;
        width: 1px;
        color: $borderColor;
    }

    color: $textColor;
    background-color: $backgroundColor;
    transition: $transitionTime;

    &:hover {
        background-color: darken($backgroundColor, 10%);
    }
}

/*====================================
Text
====================================*/
@mixin textShadow {
    text-shadow: 0 1px 3px rgb(0, 0, 0 / 80%);
}

@mixin componentsTitle {
    color: $primaryColor;
    font: {
        size: 1.75rem;
        weight: 600;
    }
}

/*====================================
Transitions
====================================*/
@mixin transition {
    transition: 0.2s;
}
