.btn {
    padding: {
        top: 1rem;
        bottom: 1rem;
    }
    text-align: center;
    font-size: 0.9rem;

    /*=======================================
  -> Colors
  =======================================*/
    &.btn--blue {
        @include button($contrastDarkText, $primaryColor);
    }

    &.btn--red {
        @include button($contrastDarkText, $red, $red);
    }

    &.btn--transparent {
        @include button($contrastDarkText);
    }

    /*=======================================
  -> Sizes
  =======================================*/
    &.btn--fluid {
        width: 100%;
    }

    &.btn--big {
        padding: 1rem 2.75rem;
    }

    &.btn--med {
        padding: 1rem 2rem;
    }

    &.btn--small {
        padding: 1rem 1.5rem;
    }

    /*=======================================
  -> Other styles
  =======================================*/
    &.btn--svg {
        span {
            margin-left: 0.5rem;
            background-color: $contrastDarkText;
            border-radius: 5rem;
            height: 1.5rem;
            width: 1.5rem;
            display: inline-block;
            text-align: center;
        }

        svg {
            color: $primaryColor;
            margin-left: 0.17rem;
            width: 0.425rem !important;
            margin-top: -2px;
        }
    }
}
