.form {
    label {
        font-size: 0.9rem;
    }

    &-control,
    &-select {
        background-color: $grayFormBackground;
        border: {
            width: 1px;
            style: solid;
            color: $grayFormBackground;
            radius: 0;
        }
        font-size: 0.9rem;

        &:focus {
            background-color: lighten($grayFormBackground, 3%);
            border: {
                color: lighten($grayFormBackground, 3%);
            }
            box-shadow: 0 0 0 0.25rem $grayFormBackground;
        }
    }

    &-check-input {
        border: {
            width: 1px;
            style: solid;
            color: $grayFormBackground;
            radius: 0 !important;
        }
        background-color: $grayFormBackground;
    }
}
