.message {
    margin-bottom: 1.5rem;

    .message {
        &__svg,
        &__text {
            padding: 1.5rem;
            color: $contrastDarkText;
        }

        &__svg {
            div {
                position: relative;
                margin: 0 auto;
                top: 50%;
                display: block;
                transform: translateY(-50%);
                width: fit-content;
            }

            svg {
                vertical-align: unset;
            }
        }

        &__text {
            p {
                margin-bottom: 0;
            }

            ul {
                margin-bottom: 0;
                padding: 0;

                li {
                    list-style: none;
                }
            }
        }
    }

    &--error {
        .message {
            &__svg {
                background-color: darken($red, 10%);
            }

            &__text {
                background-color: $red;
            }
        }
    }

    &--alert {
        .message {
            &__svg {
                background-color: darken($yellow, 10%);
            }

            &__text {
                background-color: $yellow;
            }
        }
    }

    &--success {
        .message {
            &__svg {
                background-color: darken($green, 10%);
            }

            &__text {
                background-color: $green;
            }
        }
    }
}
