.offcanvas {
    .offcanvas {
        &-header {
            align-items: start;

            .btn-close {
                margin-top: 0.5rem;

                background: {
                    size: 0.8em;
                }
            }
        }

        &-title {
            @include componentsTitle;

            margin: 0;
        }
    }
}
