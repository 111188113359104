.page__queryContent {
    margin-bottom: 2rem;

    .btn {
        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        @include media-breakpoint-down(md) {
            margin-top: 1rem;
        }
    }
}
