.page__header {
    background: {
        image: url("../images/banner-conteudo.jpg");
        size: cover;
        position: center;
        repeat: no-repeat;
    }

    margin-bottom: 2rem;
    padding: 1rem 2rem;

    .page__header {
        &__title {
            margin: 0;
            color: $contrastDarkText;
            font-weight: 700;
            font-size: 2rem;
            @include textShadow;
        }

        &__subtitle {
            margin: 0;
            color: $contrastDarkText;
            font-weight: 400;
            font-size: 1.2rem;
            @include textShadow;
        }
    }
}

.page__content {
    padding: 2.5rem 2rem 2.5rem 2rem;
    background: {
        color: $lightBackground;
    }
    color: $contrastLightText;

    p,
    li {
        font-size: 0.95rem;
    }
}
