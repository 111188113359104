.printQuery {
    background-color: #7b847c;
    width: fit-content;
    padding: 1rem;
    border-bottom-left-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
    display: block;
    margin-left: auto;
    margin-top: -4rem;
    margin-bottom: calc(4rem - 56px);

    @media print {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    svg {
        width: 1rem;

        path {
            fill: #fff;
        }
    }
}

/*========================================
GERAL
========================================*/
.page__resultQuery {
    padding: 2rem 3rem;
}

.b-acao {
    z-index: 1;

    a {
        background-color: #7b847c;
        text-align: center;
        display: block;
        width: 3.1rem;
        padding: 0.35rem 0 0.55rem;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        margin-bottom: -100%;

        svg {
            width: 0.9rem;

            path {
                fill: #fff;
            }
        }
    }
}

.titulo-consulta-imp h3 {
    font-size: 2rem;
    font-weight: 700;
}

.info-preliminar p {
    margin: 0;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 200;
}

.bloco-retorno {
    border-bottom: 5px solid #000;
    padding: 2rem 1.5rem;
}

.observacao {
    margin-top: 0.5rem;
    font-size: 14px;
    margin-bottom: 0;
}

.titulo-bloco-retorno {
    hr {
        margin-top: 0;
        margin-bottom: 0.5rem;
        border: 0;
        border-top: 0.8rem solid $primaryColor;
        width: 0.8rem;
        margin-left: 0;
    }

    h5 {
        font-size: 1.4rem;
        font-weight: 600;
    }
}

/*========================================
Score
========================================*/
.bloco-score {
    .titulo-bloco-retorno {
        margin-bottom: 2rem;
    }

    .gridScore {
        background-color: #efefef;

        @media screen and (max-width: 767px) {
            padding: 3rem 2rem 2.9rem;
        }

        .gridScore__svg {
            @media screen and (max-width: 767px) {
                margin: -2.2rem auto -1.5rem;
            }
        }
    }

    .score {
        h5,
        p {
            margin: 0;
            text-align: center;
        }

        h5 {
            font-weight: 700;
            color: #ff0051;

            @media screen and (max-width: 767px) {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }

        .retorno-score {
            border-left: 5px solid #fff;

            @media screen and (max-width: 767px) {
                border-left: 0;
            }

            p {
                padding: 1rem 0;
                font-size: 2.5rem;

                @media screen and (max-width: 767px) {
                    padding: 0;
                    font-size: 2rem;
                    margin: 0;
                }
            }
        }

        svg {
            height: 13rem;
            margin-top: -1rem;
            margin-bottom: -1rem;

            @media screen and (max-width: 767px) {
                -ms-transform: rotate(90deg); /* IE 9 */
                transform: rotate(90deg);
            }
        }

        .st0 {
            fill: #656363;
        }

        .st1 {
            fill: #808080;
        }

        .st2 {
            fill: #999999;
        }

        .st3 {
            fill: #b3b3b3;
        }

        .st4 {
            fill: #d6d6d6;
        }
    }
}

.score {
    .valor-score-a .st4 {
        fill: #64b110 !important;
    }

    .valor-score-b .st3 {
        fill: #cbd400 !important;
    }

    .valor-score-c .st2 {
        fill: #fee517 !important;
    }

    .valor-score-d .st1 {
        fill: #f9a11f !important;
    }

    .valor-score-e .st0 {
        fill: #cf3534 !important;
    }

    .valor-score-f .st0,
    .valor-score-g .st0 {
        fill: #656363 !important;
    }
}

.bloco-score .observacao {
    margin-top: 1.5rem;
}

/*========================================
Retorno em grid
========================================*/
.retorno-grid {
    padding: 1rem;
    border-bottom: 1px solid #e8e8e8;

    h6 {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 600;
    }

    p {
        font-size: 0.85rem;
        font-weight: 300;
        margin: 0;
    }

    &.nada-consta {
        margin-top: 0.5rem;
        background-color: #ececec;
        border-color: #ececec;

        p {
            color: #797979;
        }
    }
}

/*========================================
Retorno em table
========================================*/
.bloco-retorno {
    .table {
        margin-top: 0.5rem;
        margin-bottom: 0;

        thead th {
            border-color: #efefef;
            background-color: #efefef;
            font-size: 0.9rem;
        }

        td {
            padding: 0.45rem 0.75rem;
            font-size: 0.8rem;
        }
    }

    .table-w-grid {
        margin-top: 0;

        thead th {
            background-color: #efefef;
            border-color: #e8e8e8;
        }

        .nada-consta {
            td {
                background-color: #ececec;
                border-color: #ececec;
                border-bottom: 1px solid #dee2e6;

                &:hover {
                    background-color: #ececec;
                }
            }
        }

        &.b-t-0 {
            thead th {
                border: 0;
            }
        }
    }

    .table-bottom {
        margin-top: 0;
        margin-bottom: 1.5rem;

        td {
            border-top: 0;
            border-bottom: 1px solid #dee2e6;
        }
    }
}

/*========================================
Aviso júridico
========================================*/
.aviso-juridico {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/*========================================
Versão de impressão
========================================*/
@media screen {
    .oc-screen {
        display: none;
    }
}

@media print {
    * {
        background: none !important;
    }

    .resultado-query {
        color: #444444 !important;
    }

    .oc-screen {
        display: block;
    }

    .oc-print,
    #jvlabelWrap {
        display: none;
    }

    .logo-impressao {
        margin-top: 0;
        width: 250px;
        margin-bottom: 2rem;
        margin-left: 0;
    }

    .page__header {
        margin-bottom: 0;

        .page__header__title {
            margin: 0;
            color: #000;
            font-size: 2rem;
            text-shadow: unset;
        }

        .page__header__subtitle {
            margin: 0;
            color: #000;
            font-size: 1.1rem;
            text-shadow: unset;
        }
    }

    .page__resultQuery {
        padding: 1rem 2rem;

        h5 {
          font-size: 1.1rem !important;
        }

        h6, p, li, th, td {
            font-size: .7rem !important;
        }
    }

    .bloco-score .score svg {
        height: 13rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}

@page {
    margin: 1.5cm;
}
