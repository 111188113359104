.footer {
    padding: 2.5rem 2rem 2.5rem 2rem;
    margin: 2rem auto 2rem;

    background: {
        color: $lightBackground;
    }

    font: {
        size: 0.85rem;
    }
    color: $contrastLightText;

    p {
        margin: 0;
    }

    .footer {
        &__brandName {
            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        &__backToTop {
            text-align: right;

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-top: 1rem;
            }

            a {
                text-decoration: none;
                color: $contrastLightText;
            }

            svg {
                color: $primaryColor;
                margin-left: 0.7rem;
                width: 0.875em;
            }
        }

        &__content {
            padding: 2.5rem 1.5rem;

            &--line {
                padding: {
                    top: 2rem;
                    bottom: 2rem;
                }

                border-top: {
                    width: 1px;
                    style: solid;
                    color: rgba(0, 0, 0, 0.2);
                }

                border-bottom: {
                    width: 1px;
                    style: solid;
                    color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        &__nav {
            padding: {
                left: 0;
            }

            border-right: {
                width: 1px;
                style: solid;
                color: rgba(0, 0, 0, 0.2);
            }

            @include media-breakpoint-down(sm) {
                border: 0;
            }

            &:first-child {
                ul {
                    padding-left: 0 !important;
                }
            }

            &:last-child {
                border-right: none;
            }

            ul {
                margin-bottom: 0;
                padding-left: 1rem;
            }

            &Item {
                list-style: none;
                margin: {
                    bottom: 0.4rem;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: $contrastLightText;
                    text-decoration: none;
                    @include transition;

                    &:hover {
                        color: $primaryColor;
                    }
                }

                @include media-breakpoint-down(md) {
                    text-align: center;
                }
            }
        }

        &__contact {
            text-align: right;

            &__number {
                font: {
                    size: 1.4rem;
                    weight: 600;
                }
            }

            &__email {
                margin-bottom: 1rem;
            }

            &__relationContent {
                max-width: 20rem;
                margin: 0 0 0 auto;

                @include media-breakpoint-down(md) {
                    margin: 0;
                    max-width: unset;
                }
            }

            @include media-breakpoint-down(md) {
                text-align: center;
                margin-top: 2rem;
            }
        }

        &__brandLogo {
            img {
                width: 10rem;

                @include media-breakpoint-down(md) {
                    margin: 0 auto;
                    display: block;
                }
            }
        }

        &__pagseguro {
            img {
                width: 13rem;
                float: right;

                @include media-breakpoint-down(md) {
                    margin: 1rem auto 0;
                    display: block;
                    float: unset;
                }
            }
        }
    }
}
