/*=======================================
-> Containers
=======================================*/
$container-max-widths: (
    sm: 640px,
    md: 820px,
    lg: 1060px,
    xl: 1340px,
    xxl: 1520px,
);

/*=======================================
-> Offcanvas
=======================================*/
$offcanvas-horizontal-width: 550px;
$offcanvas-padding-y: 1rem;
$offcanvas-padding-x: 3rem;
