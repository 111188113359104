.navbar {
    .navbar {
        &-brand {
            padding: 1rem 0;
            margin-right: 0.5rem;

            img {
                width: 11.6rem;
                border-right: 1px solid #fff;
                padding-right: 1.6rem;

                @include media-breakpoint-down(lg) {
                    border-right: none;
                    padding-right: 0;
                }
            }
        }

        &-nav {
            .nav-link {
                padding: {
                    right: 1rem;
                    left: 1rem;
                }

                font-size: 0.9rem;
                color: $contrastDarkText;
            }

            .dropdown-item {
                font-size: 0.9rem;
                color: $contrastLightText;

                small {
                    font: {
                        size: 0.6rem;
                        weight: 700;
                    }
                    color: $primaryColor;
                }
            }

            &:last-child {
                margin-left: auto;
            }

            &-buttons {
                .nav-link {
                    @include button($contrastLightText, transparent);

                    padding: 0.775rem 1.5rem;

                    &.nav-item-action {
                        margin-left: 1rem;

                        @include button(
                            $contrastDarkText,
                            $primaryColor,
                            $primaryColor
                        );
                    }

                    @include media-breakpoint-down(lg) {
                        margin-left: 1rem;
                        margin-bottom: 0.6rem;
                        color: $contrastDarkText;
                    }
                }
            }

            &-logged {
                .nav-link {
                    color: $primaryColor;
                    font: {
                        weight: 600;
                    }
                }

                svg {
                    margin-right: 0.25rem;
                    margin-bottom: 0.15rem;
                }
            }
        }

        &-toggler {
            &:focus {
                box-shadow: none;
            }
        }

        &-hamburguer {
            display: block;
            height: 0.3rem;
            width: 2.3rem;
            background-color: $primaryColor;
            margin-bottom: 0.4rem;

            @include media-breakpoint-down(md) {
                background-color: $contrastDarkText;
            }

            &:last-child {
                margin: 0;
            }
        }
    }
}
