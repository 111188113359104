.modal {
    &-content {
        border: {
            width: 0;
            radius: 0;
        }

        background: {
            color: transparent;
        }
    }

    &-header {
        padding: 0;
        border: 0;

        .btn-close {
            margin: 0 0 0.3rem auto;
            opacity: 1;
            background: none;
            color: $contrastDarkText;
            padding-right: 0;
            font: {
                size: 1.5rem;
                weight: 200;
            }
        }
    }

    &-body {
        padding: 1rem 3rem;
        background: {
            color: $lightBackground;
        }
        border-top: {
            color: $primaryColor;
            width: 5px;
            style: solid;
        }

        &__title {
            @include componentsTitle;

            margin: {
                top: 0.6rem;
                bottom: 1.5rem;
            }
        }
    }
}

.modalAlert {
    .modal-body {
        padding: 3rem 3rem calc(3rem + 10px);
        background: {
            color: $primaryColor;
        }

        p {
            margin: 0;
            font: {
                size: 1.8rem;
            }

            a {
                color: $contrastDarkText;
            }
        }
    }
}
