// Text Colors
$contrastLightText: #212529;
$contrastDarkText: #ffffff;
$greyText: #90969c;

// Backgrounds
$lightBackground: #fff;
$grayFormBackground: #ededed;

// Geral Colors
$primaryColor: #3fb9f0;
$red: #a30006;
$green: #198754;
$yellow: #b6b310;

// Transition
$transitionTime: 0.2s;
