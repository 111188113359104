@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");

h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

.title {
    margin: {
        top: 0.5rem;
        bottom: 0.5rem;
    }
    font: {
        size: 2rem;
        weight: 700;
    }
    text-align: center;
}

a {
    color: $primaryColor;
    text-decoration: none;
    @include transition;

    &:hover {
        color: darken($primaryColor, 20%);
    }
}
