.pagination {
    margin-top: 1rem;

    .page-item {
        &:first-child,
        &:last-child {
            .page-link {
                border-radius: 0;
            }
        }

        &.disabled {
            .page-link {
                color: $greyText;
            }
        }

        .page-link {
            font-size: 0.9rem;
            color: $primaryColor;
        }
    }
}
